module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styletron/gatsby-browser.js'),
      options: {"plugins":[],"prefix":"_","debug":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"North Shore Commissary","short_name":"NSC","start_url":"/","background_color":"#28753F","theme_color":"#28753F","display":"minimal-ui","icon":"src/assets/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"715791f29dc56e892facb192645e2252"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-187202131-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MV8FNSF","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"North Shore Commissary"}},"dataLayerName":"NSC_MAIN_SITE"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
